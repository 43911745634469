import * as React from 'react'
import Card from '../Card'
import CTACard from '../CTACard'
import {
  SocialMedia,
  Footer as StyledFooter,
  FooterLink,
  FooterItem,
  FooterList,
  FooterInfo,
  StyledFlex,
} from './styles'
import { Container, Cards } from '../styles'
import Logo from '../Logo'
import styled from 'styled-components'
// import Instagram from 'react-icons/lib/fa/instagram'
// import Facebook from 'react-icons/lib/fa/facebook'
// import Google from 'react-icons/lib/fa/google-plus'
// import Twitter from 'react-icons/lib/fa/twitter'
import footerLinks from './footer'
import { NavLogo } from '../Navbar/styles'

const CardsContainer = styled(Container)`
  margin-bottom: 50px;
  margin-top: 100px;
  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

const Footer: React.SFC = () => (
  <>
    <CardsContainer>
      <Cards>
        <Card
          color="primary"
          shadow={true}
          title="Fully Licensed"
          content="We are fully licensed and highly experienced in our field, to ensure the highest quality workmanship on every job. QBCC No: 1026222"
        />

        <Card
          color="tertiary"
          shadow={true}
          title="Trusted"
          content="We service all areas of Brisbane and the Sunshine Coast, and with decades of industry experience. Call us to arrange a free quote."
        />

        <Card
          color="purple"
          shadow={true}
          title="Simple"
          content="Our goal is to make the process as simple as possible for you. We will guide you through the process every step of the way. "
        />
      </Cards>
    </CardsContainer>
    <StyledFooter>
      <FooterInfo>
        <NavLogo src="/assets/logo.png" />
        <p style={{ marginTop: 10 }}>
          © 2022 Just Patios, Pty Ltd <br />
          All rights reserved.
        </p>
        <SocialMedia>
          <a target="_blank" href="https://facebook.com/JustPatios">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
            </svg>
          </a>
        </SocialMedia>
      </FooterInfo>
      <StyledFlex>
        {footerLinks.map((item) => (
          <FooterList key={item.text}>
            <span>{item.text}</span>
            {item.links.map((link) =>
              link.target ? (
                <FooterItem key={link.text} href={link.to} target={link.target}>
                  {link.text}
                </FooterItem>
              ) : (
                <FooterLink key={link.text} to={link.to || '#'}>
                  {link.text}
                </FooterLink>
              )
            )}
          </FooterList>
        ))}
        <FooterList>
          <span>Details</span>
          <FooterItem>Just Patios QLD Pty Ltd</FooterItem>
          <FooterItem>412-420 Old Gympie Rd, Caboolture QLD 4510</FooterItem>
          <FooterItem>sales@justpatios.com.au</FooterItem>
          <FooterItem href="tel:1300284531">PH: 1300 284 531</FooterItem>
        </FooterList>
      </StyledFlex>
    </StyledFooter>
  </>
)

export default Footer
