import * as React from 'react'
import styled from 'styled-components'

interface ICardProps {
  shadow?: boolean
  border?: boolean
  color: string
}

const Card = styled<ICardProps, any>('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
  color: ${props => props.theme.colors[props.color || 'primary']};
  box-shadow: ${props =>
    props.shadow ? '0 6px 18px 0 rgba(46, 50, 128, 0.15)' : 'none'};
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-top: ${props => (props.shadow ? '3px solid currentColor' : 'none')};
  margin-bottom: 1rem;
  border-radius: 3px;

  @media (min-width: 940px) {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 600px) {
    padding: 2rem;
  }

  &:hover,
  &:focus,
  &:active {
    transform: ${props => (props.shadow ? 'translateY(-3px)' : 'none')};
    box-shadow: ${props =>
      props.shadow ? '0 8px 18px 0 rgba(46, 50, 128, 0.25)' : 'none'};
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  p {
    color: #3d4677;

    @media (min-width: 1180px) {
      margin-bottom: 2rem;
      width: 90%;
    }
  }

  h5 {
    color: currentColor;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 24px;
    height: 24px;
    margin-bottom: 0.75rem;

    @media (min-width: 940px) {
      margin-bottom: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }
`

interface CardProps {
  title: string
  content: string
  children?: React.ReactChild
  color: string
  shadow?: boolean
  border?: boolean
}

export default ({ title, content, children, ...rest }: CardProps) => {
  return (
    <Card {...rest}>
      {children}
      <h4>{title}</h4>
      <p>{content}</p>
      <h5>{title}</h5>
    </Card>
  )
}
