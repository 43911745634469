export default [
  {
    text: 'Home',
    to: '/'
  },
  {
    text: 'Patios',
    to: '/patio-builders-brisbane/'
  },
  // {
  //   text: 'Carports',
  //   to: '/carport-builders-brisbane/'
  // },
  {
    dropdown: [
      {
        text: 'Patios',
        to: '/patio-builders-brisbane/'
      },
      {
        text: 'Carports',
        to: '/carport-builders-brisbane/'
      },
      {
        text: 'Glass Room Enclosures',
        to: '/room-enclosures-brisbane/'
      },
      {
        text: 'Roofing',
        to: '/patio-roofing-brisbane/'
      },
      {
        text: 'DIY Installation Kits',
        to: '/diy-patio-kits/'
      }
    ],
    width: '220px',
    text: 'Services'
  },
  {
    dropdown: [
      {
        text: 'On Site Quote',
        to: '/on-site-quote/'
      },
      {
        text: 'Online Estimate',
        to: '/online-estimate/'
      }
    ],
    width: '180px',
    text: 'Free Quotes'
  },
  {
    dropdown: [
      {
        text: 'About',
        to: '/about-us/'
      },
      {
        text: 'Testimonials',
        to: '/testimonials/'
      },
      {
        text: 'FAQs',
        to: '/faqs/'
      },
      {
        text: 'Council Approval',
        to: '/council-approval/'
      },
      {
        text: 'Brochure',
        target: '_blank',
        to: '/assets/just-patios-brochure.pdf'
      },
      {
        text: 'Careers',
        to: '/careers/'
      },
      {
        text: 'Blog',
        to: '/blog/'
      }
    ],
    width: '180px',
    text: 'Why Us?'
  },
  {
    text: 'Contact',
    to: '/contact-us/'
  },
  {
    text: 'Free Quote',
    to: '/contact-us/'
  }
]
