export default [
  {
    text: 'Services',
    links: [
      {
        text: 'Patios',
        to: '/patio-builders-brisbane/'
      },
      {
        text: 'Carports',
        to: '/carport-builders-brisbane/'
      },
      {
        text: 'Enclosures',
        to: '/room-enclosures-brisbane/'
      },
      {
        text: 'Roofing',
        to: '/patio-roofing-brisbane/'
      },
      {
        text: 'DIY Kits',
        to: '/diy-patio-kits/'
      }
    ]
  },
  {
    links: [
      {
        text: 'About',
        to: '/about-us/'
      },
      {
        text: 'Testimonials',
        to: '/testimonials/'
      },
      {
        text: 'FAQs',
        to: '/faqs/'
      },
      {
        text: 'Council Approval',
        to: '/council-approval/'
      },
      {
        text: 'Brochure',
        target: '_blank',
        to: '/assets/just-patios-brochure.pdf'
      },
      {
        text: 'Careers',
        to: '/careers/'
      },
      {
        text: 'Blog',
        to: '/blog/'
      }
    ],
    text: 'About'
  },
  {
    links: [
      {
        text: 'Contact',
        to: '/contact-us/'
      },
      {
        text: 'On Site Quote',
        to: '/on-site-quote/'
      },
      {
        text: 'Online Estimate',
        to: '/online-estimate/'
      },
      {
        text: 'Privacy Policy',
        to: '/privacy-policy/'
      }
    ],
    text: 'Contact'
  }
]
